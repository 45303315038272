1
<template>
    <div class="headerDiv">
        <van-nav-bar title="新增带看" class="nav_bar_title" @click-left="leftReturn" fixed left-arrow/>

        <div class="normalBlock">
            <span class="maker" :class="{'orange':(isYes||isNo)}">• </span>
            <span class="blockTitle">是否空看？</span>
            <div class="rightText">
                <span class="passText" :class="{'choosedStyle':isYes}" @click="chooseYes">是</span>
                <span class="content-divide"> | </span>
                <span class="rejectText" :class="{'choosedStyle':isNo}" @click="chooseNo">否</span>
            </div>
        </div>
        <div class="userInfo">客户信息</div>

        <div class="part-inputpart-row">
            <span :class="!selectUser ? 'maker':'orange'">• </span>
            <span class="part-inputpart-row-header">客户</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="!selectUser ? '':'part-inputpart-row-normaltext' ">{{!selectUser?'选择客户':selectUser}}</span>
        </div>
        <!--预约房源-->
        <div class="part-inputpart-row" @click="clickroomHouseIdListShow">
            <span :class="!selectroomName ? 'maker':'orange'">• </span>
            <!--预约房源-->
            <span class="part-inputpart-row-header">预约房源</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="!selectroomName ? '':'part-inputpart-row-normaltext' ">{{!selectroomName?'选择房源':selectroomName}}</span>
            <img class="part-inputpart-row-right"
                 :class=" isroomHouseIdShow ? 'complaintTypedropDownList-upArrow' : 'complaintTypedropDownList-downArrow' "
                 src="../../assets/images/triangle.png">
        </div>

        <div>
            <!--        预约房源-->
            <van-popup v-model="isBankTypeSelectRoom" position="bottom">
                <van-search  placeholder="请输入" v-model="selectBankTypeRoomName" size="large" @search="initRoomHouseData()"/>
                <van-picker show-toolbar :columns="roomNameList" value-key="roomName" item-height="55px"
                            @cancel="isBankTypeSelectRoom = false" @confirm="selectBankTypeRoom"/>
            </van-popup>
        </div>

        <div id="checkInDatePanel" class="part-inputpart-row" @click="clickCheckInDateShow">
            <span :class="0==checkInDate.trim().length ? 'maker':'orange'">• </span>
            <span class="part-inputpart-row-header">时间</span>
            <span class="content-divide">|</span>
            <span class="date-graytext">{{checkInDate}}</span>
            <img class="part-inputpart-row-right-timepick" src="../../assets/images/timepick.png">
        </div>
        <div>
            <van-popup v-model="isCheckInDateShow" position="bottom" class="selectTimePopup">
                <div class="date-dropDownList">
                    <van-datetime-picker
                            v-model="currentDate"
                            type="date"
                            title="选择年月日"
                            item-height="35px"
                            @confirm="checkInDateConfirm"
                            @cancel="checkInDateCancel"
                    />
                </div>
            </van-popup>

        </div>

        <van-button
                :class="[!selectUser || !selectroomName  || !checkInDate || !isYes && !isNo? 'saveButton_Disable':'saveButton_Enable' ]"
                :disabled=" !selectUser || !selectroomName   || !checkInDate|| !isYes && !isNo"
                @click="saveAppointmentData">
            确认
        </van-button>
    </div>
</template>

<script>

    import {
        NavBar,
        Button,
        DatetimePicker,
        Uploader,
        Popup,
        Picker,
        search
    } from "vant";
    import {seeWithRoomList, addUserAppointment,} from "../../getData/getData";
    import {
        getStaffId,
        responseUtil,
        globaluserId,
    } from '../../libs/rongxunUtil';
    import Vue from 'vue';

    Vue.use(Popup, Picker);

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Button.name]: Button,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Picker.name]: Picker,
            [search.name]:search,
            [Popup.name]: Popup,
        },
        name: "userInfo",
        data() {
            return {
                selectBankTypeRoomName:'', //
                UserIndex: '',
                roomHouseIdIndex: '',
                isYes: false,
                isNo: false,
                selectUser: '',///客户名称
                selectroomName: '',//房源名称
                selectroomHouseId: '择房源',
                isUserShow: false,
                isroomHouseIdShow: false,
                isUserSelect: '',
                isroomHouseIdSelect: '',
                isBankTypeSelect: false,//客户择钮
                isBankTypeSelectRoom: false,//房源择钮
                showType: [],//客户人员列表
                roomNameList: [],//房源名称列表
                isNoManSee: '',//是否空看
                appointmentTime: '',//预约时间
                icon: [
                    {first: '../../assets/images/potion_icon.png'},
                    {last: '../../assets/images/potion_icon2.png'},
                ],
                UserArr: [
                    '王小姐',
                    '李先生',
                    '周小姐',
                ],
                roomHouseIdList: [],
                formAppointmentValidate: [],
                //受理日期
                isCheckInDateShow: false,
                currentDate: new Date(),
                checkInDate: '',
            }
        },
        created: function () {
            this.selectUser = this.$route.query.userName
            this.formAppointmentValidate[0] = this.$route.query.userId
            console.log(this.selectUser + "   " + this.formAppointmentValidate)
        },
        mounted: function () {
            // this.init();
            this.initRoomHouseData();
        },
        methods: {
            //客户择
            selectBankType(item) {
                this.selectUser = item.userName;
                this.isBankTypeSelect = false//客户择钮
                // this.userId=item.id
                // this.formAppointmentValidate[0]=item.id
            },
            //房源择
            selectBankTypeRoom(item) {
                this.selectroomName = item.roomName;
                this.roomHouseIdList[0] = item.id;
                this.isBankTypeSelectRoom = false//客户择钮
            },
            //返回上一页
            leftReturn() {
                this.$router.go(-1);
            },
            chooseYes() {
                this.isYes = true;
                this.isNo = false;
                this.isNoManSee = "0"
            },
            chooseNo() {
                this.isNo = true;
                this.isYes = false;
                this.isNoManSee = "1"
            },

            // 检查是否已择客户
            clickUserShow() {
                // this.isBankTypeSelect=true
                // this.isUserShow = !this.isUserShow;
                // var panel = document.getElementById('userId')
                // document.addEventListener('click', e => {
                //     if (!panel.contains(e.target)) {
                //         this.isUserShow = false
                //     }
                // })
            },
            // 检查是否已择房源
            clickroomHouseIdListShow() {
                this.isBankTypeSelectRoom = true
                this.isroomHouseIdShow = !this.isroomHouseIdShow;

            },


            //日期择组件 点击确认键后执行方法
            endPeriodConfirm(value) {
                var date = new Date(value)
                this.contractInfo.endPeriod = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            },
            clickCheckInDateShow() {
                this.isCheckInDateShow = !this.isCheckInDateShow;
                var panel = document.getElementById('checkInDatePanel')
                if (panel) {
                    document.addEventListener('click', e => {
                        if (!panel.contains(e.target)) {
                            this.isCheckInDateShow = false
                        }
                    })
                }
            },
            //日期择组件 点击确认键后执行方法
            checkInDateConfirm(value) {
                var date = new Date(value)
                this.checkInDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
                this.appointmentTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T' + date.getHours() + ':' + date.getMinutes() + ':' + '00' + '.000Z'
                this.isCheckInDateShow = false
            },
            //日期择组件 点击取消键后执行方法
            checkInDateCancel() {
                this.isCheckInDateShow = false
            },
            // 获取间房源数据
            initRoomHouseData: function () {
                var that = this
                let initData = {
                    staff_id: getStaffId(),
                    selectBankTypeRoomName:that.selectBankTypeRoomName,
                    subType: "1",  
					houseType: "1",  // 只查询可租的房源
                }
                seeWithRoomList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if (response.data.code == 0) {
                            that.roomNameList = response.data.data.roomHouseList
                        }
                    })
                })
            },
            //预约带看保存
            saveAppointmentData: function () {
                var that = this
                let initData = {
                    idList: that.formAppointmentValidate,
                    // roomHouseId:that.roomHouseId,
                    staff_id: getStaffId(),
                    isNoManSee: that.isNoManSee,
                    appointmentTime: that.appointmentTime,
                    roomHouseIdList: this.roomHouseIdList
                }

                addUserAppointment(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that, response.data.msg)
                        setTimeout(() => {   //设置延迟执行
                            that.leftReturn()
                        }, 1000);
                    })
                })
            },
            //预约取消
            cancelAppointmentData: function () {
                this.$refs['addAppointmentModal'].modalFlag = false
            },
        }
    }
</script>

<style scoped>
    .headerDiv {
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .nav_bar_title {
        z-index: 99;
    }

    .rightText {
        margin-left: auto;
        font-size: 14px;
        color: #999999;
    }

    .choosedStyle {
        color: #ff3c00;
        font-weight: bold;
    }

    .complaintInfo {
        width: 48px;
        height: 17px;
        color: #FF5D3B;
        font-size: 12px;
        font-weight: bold;
        margin: 55px 0 5px 15px;
    }

    .userInfo {
        float: left;
        width: 48px;
        height: 17px;
        color: #FF5D3B;
        font-size: 12px;
        font-weight: bold;
        margin: 25px 0 5px 15px;
    }

    .normalBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        line-height: 1rem;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 60px 15px 0 15px;
    }

    /*投诉信息列表*/
    .complaintInfoList {
        margin: 0 15px 0 15px;
        padding-top: 10px;
        height: 170px;
        background-color: white;
        border-radius: 10px;
    }

    .roomDetailedAddress {
        width: 310px;
        height: 50px;
        margin-left: 15px;
        font-size: 16px;
        font-weight: bold;
    }

    /*房屋地址*/
    .roomAddress {
        float: left;
        width: 275px
    }

    /*编辑图标*/
    .edit_icon {
        height: 13px;
        width: 13px;
        float: right;
        margin-right: 5px;
        margin-top: 8px;
    }

    .line {
        width: 100%;
        margin: 5px 0 10px 0;
        border-bottom: 1px solid #f8f8f8;
    }

    /*受理信息*/
    .acceptanceInfo {
        float: left;
        width: 48px;
        height: 17px;
        color: #FF5D3B;
        font-size: 12px;
        font-weight: bold;
        margin: 20px 0 5px 15px;
    }

    .acceptanceList {
        float: left;
        margin-top: 2px;
    }

    /*受理记录跟进条*/
    .followLine {
        float: left;
        width: 13px;
        height: 70px;
        margin-left: 15px;
    }

    .followLine2 {
        float: left;
        width: 13px;
        height: 40px;
        margin-left: 15px;
    }

    .rank {
        margin-bottom: 5px;
        margin-left: 10px;
        line-height: 35px;
        height: 30px;
        font-size: 16px;
        font-weight: bold;
        float: left;
    }

    /*受理记录时间*/
    .recordTime {
        width: 130px;
        float: right;
        height: 35px;
        line-height: 35px;
        font-size: 12px;
        text-align: right;
        margin-right: 10px;
        color: gray;
    }

    /*受理记录人*/
    .recordName {
        float: left;
        margin-left: 10px;
        font-size: 13px;
        width: 100%;
        height: 30px;
        font-weight: bold;
    }

    /*受理记录人备注*/
    .explain {
        margin-left: 10px;
        font-size: 12px;
        color: gray;
    }

    .listLeft {
        float: left;
        width: 140px;
        /*height: 24px;*/
        line-height: 24px;
    }

    .listRight {
        font-size: 14px;
        display: flex;
        /*width: 170px;*/
        line-height: 24px;
    }

    .list {
        font-size: 14px;
        margin-left: 15px;
        /*width: 152px;*/
        font-weight: bold;
    }

    .label {
        width: 315px;
        height: 24px;
        font-size: 14px;
        margin-left: 14px;
        line-height: 24px;
        font-weight: bold;
    }

    /*span标签样式*/
    .sp {
        font-size: 13px;
        margin-left: 4px;
        margin-right: 4px;
        color: #7d7b7b;
    }

    /*择名称的div*/
    .part-inputpart-row {
        float: left;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 0 5px 15px 15px;
    }

    .passText {
        flex: 1;
        padding-right: 7px;
    }

    .rejectText {
        padding-left: 7px;
        padding-right: 33px;
    }

    .row2 {
        float: left;
        position: relative;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 345px;
        height: 220px;
        border-radius: 8px;
        margin: 0 5px 15px 15px;
    }

    .row3 {
        float: left;
        position: relative;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 300px;
        height: 70px;
        border-radius: 8px;
        margin: 0px 5px 0 15px;
    }

    /*圆点样式---灰色*/
    .maker {
        color: #999999;
        float: left;
        font-size: 28px;
        margin: 8px 10px 10px 22px;
    }

    /*圆点样式---橘色*/
    .orange {
        color: #ff5809;
        float: left;
        font-size: 28px;
        margin: 8px 10px 10px 22px;
    }

    /*竖线样式*/
    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin: 0 10px;
    }

    .part-inputpart-row-header {
        font-weight: bold;
        font-size: 14px;
        width: 120px;
    }

    .part-inputpart-row-graytext {
        color: black;
        width: 100%;
        font-size: 14px;
        margin-top: 1px;
    }

    .date-graytext {
        color: #ff5809;
        width: 100%;
        font-size: 14px;
        margin-top: 1px;
    }

    .part-inputpart-row-normaltext {
        color: #ff5809;
    }

    .X-graytext {
        color: black;
        width: 98%;
        font-size: 15px;
        margin-top: 1px;
    }

    .part-inputpart-row-right {
        float: right;
        margin-right: 10px;
    }

    /*择角图标的向上向下样式*/
    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-downArrow {
        width: 9px;
    }

    .complaintTypedropDownList-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .complaintTypedropDownList-downArrow {
        width: 9px;
    }

    .part-inputpart-row-right-timepick {
        width: 20px;
        margin-right: 10px;
    }

    /*下拉菜单项样式*/
    .part-inputpart-dropDownList-option {
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }

    .part-inputpart-dropDownList {
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 90px;
    }

    .complaintTypedropDownList-option {
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: black;
        float: left;
        width: 345px;
        height: 35px;
        padding-top: 10px;
        margin-left: 15px;
    }

    .complaintTypedropDownList {
        width: 100%;
        position: absolute;
        z-index: 99;
        margin-top: 155px;
    }

    .date-dropDownList {
        margin-left: 15px;
        width: 92%;
        margin-right: 10px;
        position: absolute;
        z-index: 99;
        margin-top: 5px;
    }

    /*保存钮不可点击样式*/
    .saveButton_Disable {
        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 150px 50px 15px 15px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }

    /*保存钮可点击样式*/
    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 150px 50px 15px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }

    line line_bottom {
        margin: 0;
    }

    .des {
        font-size: 13px;
        width: 100%;
    }

    textarea {
        resize: none;
    }

    .optionSelectedColor {
        color: #ff5d3b;
    }

    .blockTitle {
        overflow: hidden;
        float: left;
        font-size: 14px;
        font-weight: bold;
        width: 35%;
    }

    .selectTimePopup {
        height: 6rem;
    }
</style>
